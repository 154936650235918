
<div lang="ar" dir="ltr" class="container pos2 droid-arabic-kufi " >
    <div class="section-title">
        <h2 class=" wow zoomIn">طريقة العمل
        </h2>
        <p class="text wow zoomIn">مع خدمة توصيل بلا تواصل، صار غسيل الملابس أكثر راحة وسهولة
        </p>
    </div>
    

    
    
    
    <div class="container bg9 ">
    
        <div class="row faq bg ">
    
            <div class="col bg2 ">
                <div>
                    <div class="bg3 wow zoomIn">
                        <img class="" src="assets/img/box.png" width="50" height="50">
                    </div>
                    <a type="submit" class=" pos7 wow zoomIn" ><span class="text">نرجعها</span></a>
                </div>
            </div>
    
            <div class="col bg2">
                <div>
                    <div class=" wow zoomIn">
                        <img class="" src="assets/img/washing-machine.png" width="50" height="50">
                    </div>
                    <a  type="submit" class="pos7 wow zoomIn"><span class="text"> نغسّلها</span></a>
                </div>
            </div>
    
    
            <div class="col bg2">
                <div>
                    <div class=" wow zoomIn">
                        <img class="" src="assets/img/delivery-man.png" width="50" height="50">
                    </div>
                    <a  type="submit" class=" pos7 wow zoomIn"><span class="text">نستلمها</span></a>
                </div>
            </div>


            <div class="col bg2">
                <div>
                    <div class=" wow zoomIn">
                        <img class="" src="assets/img/sport-wear.png" width="50" height="50">
                    </div>
                    <a  type="submit" class=" pos7 wow zoomIn"><span class="text">أطلب استلام الملابس
                    </span></a>
                </div>
            </div>
    
           
        </div>
    </div>
    
    



</div>
<!-- <div class="container pos6 droid-arabic-kufi wow zoomIn">
<div class="row bg">
    <div class="col"> <div >
        <div class="single-features-item">
            
            <div class="icon">
                <img class="pos" src="assets/img/box.png" width="40" height="40">
            </div>
          
            <h3 class="">نرجعها </h3>

        </div>
    </div></div>
    <div class="col"> <div >
        <div class="single-features-item">
            <div class="icon bg2">
                <img class="pos" src="assets/img/washing-machine.png" width="40" height="40">
            </div>
            <h3>نغسّلها </h3>
        </div>
    </div></div>
    <div class="col">    <div >
        <div class="single-features-item">
            <div class="icon bg4">
                <img class="pos" src="assets/img/delivery-man.png" width="40" height="40">
            </div>
            <h3>نستلمها </h3>
        </div>
    </div></div>
    <div class="col"> <div >
        <div class="single-features-item">
            <div class="icon bg3">
                <img class="pos" src="assets/img/sport-wear.png" width="40" height="40">
            </div>
            <h3  class="text1">أطلب استلام الملابس
            </h3>
        </div>
    </div></div>
  </div>
</div>

 -->