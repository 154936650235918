<!-- <div lang="ar" dir="rtl" class="features-area pos3 ptb-100 bg-F7F7FF droid-arabic-kufi">
    <div class="container">
        <div class="row bg align-items-center">
            <div [@foobar2]="state" class="col-lg-6 col-md-12">
                <div class="features-content pos4">
                    <ul class="features-list">
                        <h5 class="pos">خدمة توصيل بلا تواصل

                        </h5>

                       
<div  class="col-lg-8 col-md-12 mt-5">
    <div class="row colacc droid-arabic-kufi" lang="ar" dir="rtl">
        <div class="col">
            <br>
            <br>
            <br>
           
        </div>

    </div>
</div>
                    </ul>

                </div>
            </div>
            <div [@foobar]="state" lang="ar" dir="rtl" class="col-lg-6 col-md-12 ">
                <div class="features-image text-center">
                                     <img src="assets/img/service-bg2.svg"  alt="app-img" data-aos="fade-up">
  <img src="assets/img/about.png">
                </div>
            </div>
        </div>
    </div>
    <div class="bg-shape1"><img src="assets/img/shape/bg-shape1.png" alt="bg-shape"></div>
</div>
 -->

<div class="container">
<div class="row">
    <div class="col-sm-6 anim ">    <div class="features-image text-center">


<img src="assets/img/cuate.svg" alt="app-img">
    </div></div>
    <div class="col-sm-6 bg1">
        <h3 class="pos droid-arabic-kufi" lang="ar" dir="rtl">خدمة توصيل بلا تواصل   </h3>
        <div class="tabs droid-arabic-kufi" lang="ar" dir="rtl">
        <div class="tab ">
            <input type="checkbox" id="chck1">
            <label class="tab-label" for="chck1">
                <p class="anim lead"> <img src="assets/img/check-box_4.svg" width="12" height="12"> &nbsp;بدون اي تواصل مباشر </p>
            </label>
            <div class="tab-content ">

                عشان راحتك، احصل على شنطة مغاسل وعلّقها على بابك. نستلم ملابسك، نغّسلها، ونوصلها
                من دون أي تواصل مباشر مع مندوب التوصيل                     </div>
        </div>
        <div class="tab">
            <input type="checkbox" id="chck2">
            <label class="tab-label " for="chck2">
                <p class="anim lead"><img src="assets/img/check-box_4.svg" width="12" height="12">&nbsp; نوافيك بالمستجدات </p>
            </label>
            <div class="tab-content ">
                نرسلّك تنبيه الى جوالك مع كل خطوة. لا تنسى تفعيل تنبيهات تطبيق مغاسل
            </div>
        </div>
       
    </div></div>
  </div>
</div>