
<div class=" droid-arabic-kufi">
    <div class="container">
        <div class="page-title-content">
            <h1 class="col2  wow zoomIn mb-4">عن مغاسل
            </h1>

        </div>
    </div>
</div>


<div class="container">
  <div class="row">
    <div class="col-sm-6 " >  <div class="col anim">
      <img src="assets/img/Illustration6.svg" width="60%">
    </div></div>
    <div class="col-sm-6 "><div class="team-area pt-100 pb-75 droid-arabic-kufi">
      <div lang="ar" dir="rtl" class="container">
          <div class="container">
              <div class="row">
            
                  <p class="col text"> 
                    مغاسل شركة حلول تقنية لقطاع المغاسل في المملكة العربية السعودية. تأسست عام 2021 وتقدم خدماتها في مدن الخبر والدمام والظهران والرياض. تعمل مغاسل على تطوير منظومة متكاملة للحلول التقنية لتمكين مزودي الخدمة من رفع مستوى الجودة والتحكم في العمليات وإدارتها، وتقليل تكلفة الخدمات بالإضافة إلى تحسين تجربة العميل. حرصاً على جودة الخدمات المقدمة للعملاء، تقوم الشركة بإبرام عقود مع مزودي الخدمة في إطار الوكالة التقنية التجارية (الامتياز التقني) وهو نموذج عمل يطبق لأول مرة في قطاع المغاسل بالمملكة.
                  </p>

            
              
              </div>
              
            </div>
      </div>
  </div></div>
  </div>

</div>
