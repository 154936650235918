<div class="bg9" lang="ar" dir="rtl">

<div class="  droid-arabic-kufi">
    <div class="container ">
        <div class="page-title-content">
            <h2 class="col2 wow zoomIn">تواصل معنا
            </h2>

        </div>
    </div>
 
</div>


<div class="contact-area ptb-100 droid-arabic-kufi">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="contact-form">

                    <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="sendMessageController()">
        
                        <div class="row">
        
                            <div class="col-lg-12 col-md-12 col-sm-12">
                               
                                <div class="form-group anim2">
                                    <input type="text" formControlName="subject" class="form-control" (focus)="reset2()" placeholder="الموضوع">
                                    <span style="color: red;">{{submsg}}</span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group anim">
                                    <input type="email" formControlName="email" class="form-control" (focus)="reset2()" placeholder="الاميل">
                                    <span style="color: red;"> {{emailmsg }} </span>
        
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div  class="form-group anim2">
                                    <input lang="ar" dir="ltr" type="text" formControlName="phone" (focus)="reset2()"  class="form-control pos" required placeholder="رقم الجوال">
                                    <span style="color: red;"> {{phonemsg}} </span>
        
                                </div>
                            </div>
                         
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group anim">
                                    <textarea formControlName="message" class="form-control" cols="30" (focus)="reset2()" rows="6" placeholder="تفاصيل الرسالة"></textarea>
                                   <span style="color: red;"> {{msgmsg}} </span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <button type="submit" class="default-btn wow zoomIn"><i class='bx bx-paper-plane'></i> إرسال رسالة</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-md-6">
                <img src="assets/img/pana.svg" alt="app-img" width="100%">
            </div>
         
        </div>
        
     
       
    </div>
  <!-- <div class="maps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46660.669043361966!2d-76.17429939609431!3d43.03529129888566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d9f3b8019f2991%3A0x58d5929e21a62e5!2sDinosaur%20Bar-B-Que!5e0!3m2!1sen!2sbd!4v1593527523138!5m2!1sen!2sbd"></iframe>
    </div> -->
</div>


