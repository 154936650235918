<div class="error-area pb-100">
    <div class="container">
        <div class="error-content">
            <img src="assets/img/error.png" alt="image">
            <p>
                ربما تمت إزالة الصفحة التي تبحث عنها إذا تم تغيير اسمها أو أنها غير متاحة مؤقتًا.
            </p>
            <a routerLink="/" class="default-btn">العودة إلى الصفحة الرئيسية</a>
        </div>
    </div>
</div>

