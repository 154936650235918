
<div class=" droid-arabic-kufi">
    <div class="container">
        <div class="page-title-content">
            <h2 class="col2 wow zoomIn">سياسة ‍ الخصوصية
            </h2>

        </div>
    </div>
 
</div>

<div class="team-area pt-100 pb-75 droid-arabic-kufi">
    <div lang="ar" dir="rtl" class="container">
        <div class="row justify-content-center">
            <br>

            <div lang="ar" dir="rtl" class="col">

                <br>


                تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا المتعلقة بجمع معلوماتك واستخدامها والكشف عنها عند استخدامك
                للخدمة

                وتخبرك بحقوق الخصوصية الخاصة بك وكيف يحميك القانون.

                نستخدم بياناتك الشخصية لتوفير الخدمة وتحسينها. باستخدام الخدمة ، فإنك توافق على جمع واستخدام المعلومات
                وفقًا لسياسة

                الخصوصية هذه. تم إنشاء سياسة الخصوصية هذه بمساعدة ‍نموذج سياسة الخصوصية ‍.


            </div>
            <br> <br>
            <br>
            <br>


            <h3 class="pos">التفسير والتعريفات
            </h3>
            <br>
            <br> <br>


            <h5>تفسير</h5>
            <br>
            <br>

            <p>الكلمات التي يتم كتابة الحرف الأول بها معاني محددة وفقًا للشروط التالية. يجب أن يكون للتعريفات التالية
                نفس المعنى بغض

                النظر عما إذا كانت تظهر بصيغة المفرد أو الجمع.

            </p>
            <br>

            <h5>تعريفات
            </h5>
            <br>
            <br>

            <p>لأغراض سياسة الخصوصية هذه:

                الحساب ‍يعني حسابًا فريدًا تم إنشاؤه لك للوصول إلى خدمتنا أو أجزاء من خدمتنا.

                التابعة ‍تعني الكيان الذي يتحكم أو يتحكم فيه أو يخضع لسيطرة مشتركة مع أحد الأطراف ، حيث تعني "السيطرة"

                ملكية 50٪ أو أكثر من الأسهم أو حقوق الملكية أو الأوراق المالية الأخرى التي يحق لها التصويت لانتخاب أعضاء

                مجلس الإدارة أو أي سلطة إدارية أخرى .

                التطبيق ‍يعني البرنامج الذي توفره الشركة الذي تقوم بتنزيله على أي جهاز إلكتروني يسمى Kleen

                الشركة (المشار إليها باسم "الشركة" أو "نحن" أو "لنا" في هذه الاتفاقية) إلى Clean Solutions IT LLC ، شارع

                الأمير مقرن ، مدينة العمال ، 7963 ، الخبر 34441 4530.

                الدولة ‍تشير إلى: المملكة العربية السعودية

                الجهاز ‍يعني أي جهاز يمكنه الوصول إلى الخدمة مثل جهاز كمبيوتر أو هاتف محمول أو جهاز لوحي رقمي.

                البيانات الشخصية ‍هي أي معلومات تتعلق بفرد محدد الهوية أو يمكن التعرف عليه.

                تابعين لجهات خارجية توظفهم الشركة لتسهيل الخدمة ، لتقديم الخدمة نيابة عن الشركة ، لأداء الخدمات المتعلقة

                بالخدمة أو لمساعدة الشركة في تحليل كيفية استخدام الخدمة.

                بيانات الاستخدام ‍إلى البيانات التي يتم جمعها تلقائيًا ، إما الناتجة عن استخدام الخدمة أو من البنية
                التحتية للخدمة نفسها

                (على سبيل المثال ، مدة زيارة الصفحة).

                أنت ‍تعني الفرد الذي يصل إلى الخدمة أو يستخدمها ، أو الشركة ، أو الكيان القانوني الآخر الذي يقوم هذا
                الفرد

                بالوصول إلى الخدمة أو استخدامها نيابةً عنه ، حسب الاقتضاء.

                أنت ‍تعني الفرد الذي يصل إلى الخدمة أو يستخدمها ، أو الشركة ، أو الكيان القانوني الآخر الذي يقوم هذا
                الفرد

                بالوصول إلى الخدمة أو استخدامها نيابةً عنه ، حسب الاقتضاء.</p>
            <br>

            <h3>جمع واستخدام بياناتك الشخصية
            </h3>
            <br>
            <br>
            <br>

            <h5>أنواع البيانات المجمعة
            </h5>
            <br>
            <br>

            <h6>بيانات شخصية
            </h6>
            <br>
            <br>

            <p>أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا بمعلومات تعريف شخصية معينة يمكن استخدامها للاتصال بك أو التعرف
                عليك. قد

                تتضمن معلومات التعريف الشخصية ، على سبيل المثال لا الحصر:

                عنوان بريد الكتروني

                الاسم الأول واسم العائلة

                العنوان ، الولاية ، المقاطعة ، الرمز البريدي / ، المدينة

                بيانات الاستخدام</p>
            <br>
            <br>

            <h6>بيانات الاستخدام
            </h6>
            <br>
            <br>

            <p>يتم جمع بيانات الاستخدام تلقائيًا عند استخدام الخدمة.

                قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت الخاص بجهازك (مثل عنوان IP) ، ونوع المتصفح
                ،

                وإصدار المتصفح ، وصفحات الخدمة التي تزورها ، ووقت وتاريخ زيارتك ، والوقت الذي تقضيه على تلك الصفحات ،
                والجهاز

                الفريد المعرفات وبيانات التشخيص الأخرى.

                عندما تصل إلى الخدمة عن طريق أو من خلال جهاز محمول ، يجوز لنا جمع معلومات معينة تلقائيًا ، بما في ذلك ،
                على سبيل

                المثال لا الحصر ، نوع الجهاز المحمول الذي تستخدمه ، والمعرف الفريد لجهازك المحمول ، وعنوان IP لجهازك
                المحمول ،

                وهاتفك المحمول نظام التشغيل ونوع مستعرض الإنترنت على الهاتف المحمول الذي تستخدمه ومعرفات الجهاز الفريدة
                وبيانات

                التشخيص الأخرى.

                قد نقوم أيضًا بجمع المعلومات التي يرسلها متصفحك عندما تزور خدمتنا أو عندما تصل إلى الخدمة عن طريق أو من
                خلال جهاز

                محمول.</p>
            <br>

            <h6>المعلومات التي تم جمعها أثناء استخدام التطبيق
            </h6>
            <br>
            <br>

            <p>أثناء استخدام تطبيقنا ، من أجل توفير ميزات تطبيقنا ، قد نجمع ، بإذن مسبق منك:

                معلومات بخصوص موقعك‍ بعد إذنك

                الصور والمعلومات الأخرى من كاميرا جهازك ومكتبة الصو‍ر بعد إذنك

                نستخدم هذه المعلومات لتوفير ميزات خدمتنا ، لتحسين وتخصيص خدمتنا. قد يتم تحميل المعلومات على خوادم الشركة
                و / أو

                خادم مزود الخدمة أو قد يتم تخزينها ببساطة على جهازك.

                يمكنك تمكين أو تعطيل الوصول إلى هذه المعلومات في أي وقت ، من خلال إعدادات جهازك.

            </p>
            <br>

            <h6>استخدام بياناتك الشخصية
            </h6>
            <br>
            <br>

            <p>يجوز للشركة استخدام البيانات الشخصية للأغراض التالية:

                لتوفير وصيانة خدمتنا ‍، بما في ذلك مراقبة استخدام خدمتنا.

                لإدارة حسابك: ‍لإدارة تسجيلك كمستخدم للخدمة. يمكن أن تمنحك البيانات الشخصية التي تقدمها إمكانية الوصول
                إلى

                الوظائف المختلفة للخدمة المتاحة لك كمستخدم مسجل.

                العقد: ‍التطوير والامتثال والتعهد بعقد الشراء للمنتجات أو الأصناف أو الخدمات التي اشتريتها أو أي عقد آخر
                معنا من

                خلال الخدمة.

                للتواصل معك: ‍للتواصل معك عن طريق البريد الإلكتروني أو المكالمات الهاتفية أو الرسائل القصيرة أو غيرها من

                أشكال الاتصالات الإلكترونية المماثلة ، مثل الإخطارات الفورية لتطبيقات الهاتف المحمول فيما يتعلق
                بالتحديثات أو

                الاتصالات الإعلامية المتعلقة بالوظائف أو المنتجات أو الخدمات المتعاقد عليها ، بما في ذلك التحديثات
                الأمنية ، عند

                الضرورة أو المعقول لتنفيذها.

                لتزويدك ‍بالأخبار والعروض الخاصة والمعلومات العامة حول السلع والخدمات والأحداث الأخرى التي نقدمها والتي

                فعالية حملاتنا الترويجية ولتقييم وتحسين خدماتنا ومنتجاتنا وخدماتنا وتسويقنا وتجربتك.

                قد نشارك معلوماتك الشخصية في المواقف التالية:

                مع مزودي الخدمة: ‍قد نشارك معلوماتك الشخصية مع مزودي الخدمة لمراقبة وتحليل استخدام خدمتنا ، للتواصل
                معك.•

                لنقل الأعمال: ‍يجوز لنا مشاركة معلوماتك الشخصية أو نقلها فيما يتعلق أو أثناء المفاوضات بشأن أي اندماج أو
                بيع أصول •

                الشركة أو تمويل أو الاستحواذ على كل أو جزء من أعمالنا إلى شركة أخرى.

                مع الشركات التابعة: ‍قد نشارك معلوماتك مع الشركات التابعة لنا ، وفي هذه الحالة سنطلب من تلك الشركات
                التابعة احترام •

                سياسة الخصوصية هذه. تشمل الشركات التابعة شركتنا الأم وأي شركات فرعية أخرى أو شركاء في المشاريع المشتركة
                أو

                شركات أخرى نسيطر عليها أو تخضع لسيطرة مشتركة معنا.

                مع شركاء الأعمال: ‍قد نشارك معلوماتك مع شركائنا في العمل لنقدم لك منتجات أو خدمات أو عروض ترويجية معينة.
                •

                مع مستخدمين آخرين: ‍عندما تشارك معلومات شخصية أو تتفاعل بطريقة أخرى في الأماكن العامة مع مستخدمين آخرين
                ، •

                فقد يتم عرض هذه المعلومات من قبل جميع المستخدمين ويمكن توزيعها علنًا في الخارج.

                بموافقتك : يجوز لنا الكشف عن معلوماتك الشخصية لأي غرض آخر بموافقتك. •</p>
            <br>

            <h6>الاحتفاظ ببياناتك الشخصية
            </h6>
            <br>
            <br>

            <p>ستحتفظ الشركة ببياناتك الشخصية فقط طالما كان ذلك ضروريًا للأغراض المنصوص عليها في سياسة الخصوصية هذه.

                سنحتفظ ببياناتك الشخصية ونستخدمها بالقدر اللازم للامتثال لالتزاماتنا القانونية (على سبيل المثال ، إذا
                كنا مطالبين بالاحتفاظ

                ببياناتك للامتثال للقوانين المعمول بها) ، وحل النزاعات ، وفرض اتفاقياتنا وسياساتنا القانونية.

                ستحتفظ الشركة أيضًا ببيانات الاستخدام لأغراض التحليل الداخلي. يتم الاحتفاظ ببيانات الاستخدام عمومًا
                لفترة زمنية أقصر ، إلا

                في حالة استخدام هذه البيانات لتعزيز الأمن أو لتحسين وظائف خدمتنا ، أو نحن ملزمون قانونًا بالاحتفاظ بهذه
                البيانات لفترات

                زمنية أطول.</p>
            <br>

            <h6>نقل بياناتك الشخصية
            </h6>
            <br>
            <br>

            <p>تتم معالجة معلوماتك ، بما في ذلك البيانات الشخصية ، في مكاتب تشغيل الشركة وفي أي أماكن أخرى حيث توجد
                الأطراف

                المشاركة في المعالجة. هذا يعني أنه قد يتم نقل هذه المعلومات إلى - والاحتفاظ بها - على أجهزة الكمبيوتر
                الموجودة خارج

                ولايتك أو مقاطعتك أو بلدك أو أي ولاية قضائية حكومية أخرى حيث قد تختلف قوانين حماية البيانات عن تلك
                الموجودة في

                ولايتك القضائية.

                موافقتك على سياسة الخصوصية هذه متبوعة بتقديمك لهذه المعلومات يمثل موافقتك على هذا النقل.

                ستتخذ الشركة جميع الخطوات اللازمة بشكل معقول لضمان التعامل مع بياناتك بشكل آمن ووفقًا لسياسة الخصوصية
                هذه ولن يتم

                نقل بياناتك الشخصية إلى منظمة أو دولة ما لم تكن هناك ضوابط كافية في مكانها بما في ذلك أمن بياناتك
                ومعلوماتك الشخصية

                الأخرى

            </p>
            <br>
            <br>

            <h6>الإفصاح عن بياناتك الشخصية
            </h6>
            <br>
            <br>

            <h6>المعاملات التجارية
            </h6>
            <br>
            <br>

            <p>إذا كانت الشركة متورطة في عملية دمج أو استحواذ أو بيع أصول ، فقد يتم نقل بياناتك الشخصية. سنقدم إشعارًا
                قبل نقل بياناتك

                الشخصية وإخضاعها لسياسة خصوصية مختلفة.</p>
            <br>
            <br>

            <h6>تطبيق القانون
            </h6>
            <br>
            <br>

            <p>في ظل ظروف معينة ، قد يُطلب من الشركة الكشف عن بياناتك الشخصية إذا طُلب منها ذلك بموجب القانون أو استجابة
                لطلبات

                صحيحة من قبل السلطات العامة (مثل محكمة أو وكالة حكومية).</p>
            <br>

            <h6>المتطلبات القانونية الأخرى
            </h6>
            <br>
            <br>

            <p>قد تفصح الشركة عن بياناتك الشخصية بحسن نية أن هذا الإجراء ضروري من أجل:

                الامتثال لالتزام قانوني •

                حماية والدفاع عن حقوق أو ممتلكات الشركة •

                منع أو التحقيق في أي مخالفات محتملة تتعلق بالخدمة •

                حماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور •

                الحماية من المسؤولية القانونية •</p>
            <br>

            <h6>أمن بياناتك الشخصية
            </h6>
            <br>
            <br>

            <p>يعد أمان بياناتك الشخصية أمرًا مهمًا بالنسبة لنا ، ولكن تذكر أنه لا توجد طريقة نقل عبر الإنترنت أو طريقة
                تخزين إلكتروني

                آمنة بنسبة 100٪. بينما نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحماية بياناتك الشخصية ، لا يمكننا ضمان
                أمنها المطلق.</p>
            <br>
            <h3>خصوصية الأطفال
            </h3> <br>
            <br>
            <br>

            <p>
                لا تخاطب خدمتنا أي شخص يقل عمره عن 13 عامًا. نحن لا نجمع عن قصد معلومات تعريف شخصية من أي شخص يقل عمره

                عن 13 عامًا. إذا كنت أحد الوالدين أو الوصي وتدرك أن طفلك قد زودنا ببيانات شخصية ، من فضلك اتصل بنا. إذا
                علمنا أننا

                جمعنا بيانات شخصية من أي شخص يقل عمره عن 13 عامًا دون التحقق من موافقة الوالدين ، فإننا نتخذ خطوات
                لإزالة هذه

                المعلومات من خوادمنا.

                إذا احتجنا إلى الاعتماد على الموافقة كأساس قانوني لمعالجة معلوماتك وطلب بلدك موافقة أحد الوالدين ، فقد
                نطلب موافقة والديك

                قبل أن نجمع هذه المعلومات ونستخدمها.</p>
            <br>
            <h3>روابط لمواقع أخرى
            </h3> <br>
            <br>
            <br>

            <p>قد تحتوي خدمتنا على روابط لمواقع أخرى لا نقوم بتشغيلها. إذا قمت بالنقر فوق ارتباط جهة خارجية ، فسيتم
                توجيهك إلى موقع

                هذا الطرف الثالث. ننصحك بشدة بمراجعة سياسة الخصوصية لكل موقع تزوره.

                ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية أو الممارسات الخاصة بأي مواقع أو
                خدمات

                خاصة بطرف ثالث.</p>
            <br> <br>

            <h3>التغييرات على سياسة الخصوصية هذه
            </h3> <br>
            <br>
            <br>

            <p>قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. سنخطرك بأي تغييرات عن طريق نشر سياسة الخصوصية

                الجديدة على هذه الصفحة.

                سنخبرك عبر البريد الإلكتروني و / أو إشعارًا بارزًا على خدمتنا ، قبل أن يصبح التغيير ساريًا وتحديث تاريخ
                "آخر تحديث" في

                الجزء العلوي من سياسة الخصوصية هذه.

                يُنصح بمراجعة سياسة الخصوصية هذه بشكل دوري لمعرفة أي تغييرات. تسري التغييرات التي تطرأ على سياسة
                الخصوصية

                هذه عند نشرها على هذه الصفحة.</p>
        </div>
    </div>
</div>
