

<div lang="ar" dir="rtl" class="features-area pos3 ptb-100 bg-F7F7FF
    droid-arabic-kufi">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12" style="visibility: visible;
                animation-duration: 1s; animation-delay: 0.1s; animation-name:
                slideInRight;"><!-- [@foobar2]="state" -->
                <div class="features-content pos4">
                    <ul class="features-list">
                        <h3 class="pos">أحصل على أفضل تجربة غسيل ملابس على بابك
                        </h3>
                        <br>
                        <li class="anim">
                            <div lang="ar" dir="rtl" class="icon pos5">
                                <img class="pos8" src="assets/img/suitcase.png"
                                    width="40" height="40">
                            </div>

                            <h5 class="text">شنطة مغاسل
                            </h5>

                            <p class="text1">مع شنطة مغاسل احصل على خدمة توصيل
                                بلا تواصل

                            </p>
                        </li>
                        <li>
                            <div lang="ar" dir="rtl" class="icon bg2 pos5">
                                <img class="pos6" src="assets/img/t-shirt.png"
                                    width="40" height="40">
                            </div>
                            <h5 class="text">غسيل فردي لكل عميل
                            </h5>
                            <p class="text1">لأن الصحة هي أغلى ما نملك، نوفر
                                خدمة الغسيل الفردي

                            </p>
                        </li>
                        <li>
                            <div class="icon bg3 pos5">
                                <img class="pos6"
                                    src="assets/img/free-delivery.png"
                                    width="40" height="40">
                            </div>
                            <h5 class="text">توصيل مجاني
                            </h5>
                            <p class="text1">توصيل مجاني لأول طلب وللطلبات بقيمة
                                ٩٩ ريال وأكثر

                            </p>
                        </li>
                    </ul>

                </div>
            </div>
            <!-- [@foobar]="state" --> <div lang="ar" dir="rtl" class="col-lg-6
                col-md-12 " style="visibility: visible; animation-duration: 1s;
                animation-delay: 0.1s; animation-name: slideInLeft;">
                <div class="features-image text-center">
                    <!--                     <img src="assets/img/service-bg2.svg"  alt="app-img" data-aos="fade-up">
 -->
                    <img class="img" src="assets/img/screen1.jpeg" width="240"
                        height="530">
                </div>
            </div>
        </div>
    </div>
    <div class="bg-shape1"><img src="assets/img/shape/bg-shape1.png"
            alt="bg-shape"></div>
</div>
