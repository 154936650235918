import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';

const style1 = style({
  opacity: 1,
  transform: "translateX(0)"
})

const style2 = style({
  opacity: 0,
  transform: "translateX(-100%)"
})
const style3 = style({
  opacity: 0,
  transform: "translateX(100%)"
})
@Component({
  selector: 'app-strategy',
  templateUrl: './strategy.component.html',
  styleUrls: ['./strategy.component.scss'],
  animations: [
    trigger('foobar', [
      state('show', style1),
      state('hide', style2),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ]),
    trigger('foobar2', [
      state('show', style1),
      state('hide', style3),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ])
  ]

})
export class StrategyComponent implements OnInit {

  state = 'hide'

  constructor(public el: ElementRef) { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', ['$event'])
   checkScroll() {
   // console.log("fff==", window.scrollY);
   // console.log("tttt==",window.pageYOffset);
  //  console.log( document.documentElement.scrollHeight)
  //console.log(document.documentElement.clientHeight);
  console.log(document.documentElement.scrollTop);
  
    const componentPosition = this.el.nativeElement.offsetTop
    const scrollPosition = window.pageYOffset
    if (window.scrollY > window.scrollY*0.0162) {
      this.state = 'show'
          
    } else {
      this.state = 'hide'
    }

  } 
 // window.pageYOffset == window.scrollY;

  /* checkScroll(){
    console.log(this.el.nativeElement.offsetHeight , 
                this.el.nativeElement.scrollHeight , 
                document.documentElement.scrollTop,
               "inf=", document.documentElement.offsetHeight);
    console.log("sup=",document.documentElement.scrollTop + document.documentElement.offsetHeight );
    


    if((document.documentElement.scrollHeight-1500) < (document.documentElement.scrollTop + document.documentElement.offsetHeight) ) {
      this.state = 'show'
          
    } else {
      this.state = 'hide'

    }
  }


  scrollTimeout: number = 100;
  scrollTimer:any;
  scrollTop!:number;
  scrollHeight!: number;
  elementHeight!: number;
  scrolling:boolean = false;
  atBottom: boolean = false;

  @HostListener('window:scroll', ['$event'])
  onScroll(e:any) { 
    this.scrolling = true;
      this.scrollTop = e.target.scrollTop;
      this.scrollHeight = e.target.scrollHeight;
      this.elementHeight = e.target.clientHeight;
      
      this.atBottom = this.scrollHeight == this.elementHeight + this.scrollTop;
      setTimeout(() => { this.scrolling = false;}, this.scrollTimeout);
        }
 */
}

