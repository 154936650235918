
<div class="bg-F7F7FF bg mt-2">
    
    <div class="droid-arabic-kufi">
        <h3 lang="ar" dir="rtl" class="text wow zoomIn">شركاؤنا الاستراتيجيون
        </h3>
    </div>
    <div class="partner-slides wow zoomIn">
        <owl-carousel-o [options]="partnerSlides">
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#" class="d-block">
                        <img src="assets/img/partner/cl1.png" alt="image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#" class="d-block">
                        <img src="assets/img/partner/cl2.png" alt="image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#" class="d-block">
                        <img src="assets/img/partner/cl3.png" alt="image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#" class="d-block">
                        <img src="assets/img/partner/cl4.png" alt="image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#" class="d-block">
                        <img src="assets/img/partner/cl5.png" alt="image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#" class="d-block">
                        <img src="assets/img/partner/cl6.png" alt="image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#" class="d-block">
                        <img src="assets/img/partner/cl7.png" alt="image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#" class="d-block">
                        <img src="assets/img/partner/cl8.png" alt="image">
                    </a>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
