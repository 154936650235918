import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactPageService } from './service/contact-page.service';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    errMsg:any
    submsg:any
    phonemsg:any
    msgmsg:any
    emailmsg:any
    success=''
    constructor(public formBuilder: FormBuilder,private contactService:ContactPageService) { 
        this.formBuilder.group(this.contactForm)

    }

    ngOnInit(): void {
    }
    contactForm= new FormGroup({
        subject: new FormControl(''),
        message: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
        phone: new FormControl('', [Validators.required]),
    
      });

      sendMessageController(){
        this.contactService.sendMessageService(this.contactForm.value).subscribe(data=>{
            console.log(data);
         alert(this.success='شكرا على اتصالك بنا')   
            
        },error=>{
            this.errMsg=error.error.message
           // console.log(this.errMsg);
           console.log(this.errMsg.split(","));
             this.submsg=this.errMsg.split(",")[0]
             this.msgmsg=this.errMsg.split(",")[1]
             this.emailmsg=this.errMsg.split(",")[2]
             this.phonemsg=this.errMsg.split(",")[4]
          this.reset()
            })
            this.reset()
      }


      
  public reset(){     
    this.contactForm.reset({ 
        subject: null,
        message: null,
        email: null,
        phone: null
    })
 
}

public  reset2(){
    this.emailmsg=''
    this.msgmsg=''
    this.submsg=''
    this.phonemsg=''
   }
}