
    <app-homeone-banner></app-homeone-banner>
    <!-- <div class="app-download-area">
        <app-download-app-style-two></app-download-app-style-two>
    </div> -->
    <!-- <app-homeone-features></app-homeone-features>
     -->
    <!-- <app-homeone-video></app-homeone-video> -->
    <div class="features-area ptb-100 bg-F7F7FF">
        <app-method></app-method>
    </div>
    
    <app-strategy></app-strategy>
    

    
    <!-- <app-progress-style-one></app-progress-style-one>
     -->
    
    
    <!-- <div class="screenshots-area bg-color ptb-100">
        <app-screenshots-style-one></app-screenshots-style-one>
    </div> -->
    
    <!-- <app-software-integrations></app-software-integrations>
     -->
    <!-- <div class="pricing-area bg-gradient-color pt-100 pb-75">
        <app-pricing-style-one></app-pricing-style-one>
        <div class="shape7"><img src="assets/img/shape/shape6.png" alt="shape"></div>
        <div class="shape8"><img src="assets/img/shape/shape7.png" alt="shape"></div>
    </div> -->
    <app-service></app-service>
    <div class="partner-area bg-f9f9f9 ptb-100">
        <div class="container">
            <div class="partner-title">
            </div>
            <!--
     <app-partner></app-partner>
            -->
       
        </div>
    </div>
    

