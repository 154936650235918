
<div class=" droid-arabic-kufi wow zoomIn">
    <div class="container">
        <div class="page-title-content">
            <h2 class="col2 ">الأحكام والشروط         </h2>

        </div>
    </div>
 
</div>


<div lang="ar" dir="rtl" class="about-area ptb-100 droid-arabic-kufi ">
    <div class="container">
        <div class="row justify-content-center">

            <h3>التفسير والتعريفات
            </h3>
            <br><br><br>
            <h5>تفسير</h5>
            <br><br>
            <p>الكلمات التي يتم كتابة الحرف الأول بها معاني محددة وفقًا للشروط التالية. يجب أن يكون للتعريفات التالية
                نفس المعنى بغض

                النظر عما إذا كانت تظهر بصيغة المفرد أو الجمع.</p>
        </div>
        <br>
        <h5>تعريفات</h5>
        <br>
        <p>الكلمات التي يتم كتابة الحرف الأول بها معاني محددة وفقًا للشروط التالية. يجب أن يكون للتعريفات التالية نفس
            المعنى بغض

            لأغراض هذه الشروط والأحكام:

            Kleenالتطبيق ‍يعني البرنامج الذي توفره الشركة الذي تقوم بتنزيله على أي جهاز إلكتروني يسمى

            أو Apple Inc. (Apple App Store) متجر التطبيقات متجر التطبيقات ‍يعني خدمة التوزيع الرقمي التي تديرها وتطورها
            شركة

            Google Inc. (متجر Google Play) والتي تم تنزيل التطبيق فيها.

            التابعة ‍تعني الكيان الذي يتحكم أو يتحكم فيه أو يخضع لسيطرة مشتركة مع أحد ‍الأطراف ، حيث تعني "السيطرة"

            ملكية 50٪ أو أكثر من الأسهم أو حقوق الملكية أو الأوراق المالية الأخرى التي يحق لها التصويت لانتخاب أعضاء

            مجلس الإدارة أو أي سلطة إدارية أخرى .

            الدولة ‍تشير إلى: المملكة العربية السعودية

            الشركة (المشار إليها باسم "الشركة" أو "نحن" أو "لنا" في هذه الاتفاقية) إلى Clean Solutions IT LLC ، شارع

            الأمير مقرن ، مدينة العمال ، 7963 ، الخبر 34441 4530.

            الجهاز ‍يعني أي جهاز يمكنه الوصول إلى الخدمة مثل جهاز ‍كمبيوتر أو هاتف محمول أو جهاز لوحي رقمي.

            الخدمة ‍إلى التطبيق.

            الشروط والأحكام (يشار إليها أيضًا باسم "الشروط") تعني هذه الشروط والأحكام التي تشكل الاتفاقية الكاملة بينك

            وبين الشركة فيما يتعلق باستخدام الخدمة. تم إنشاء اتفاقية الشروط والأحكام هذه بمساعدة نموذج ‍الشروط والأحكام
            ‍.

            خدمة الوسائط الاجتماعية التابعة لجهة خارجية ‍أي خدمات أو محتوى (بما في ذلك ‍البيانات أو المعلومات أو

            المنتجات أو الخدمات) التي تقدمها جهة خارجية والتي قد يتم عرضها أو تضمينها أو إتاحتها بواسطة الخدمة.

            أنت ‍تعني الفرد الذي يصل إلى الخدمة أو ‍يستخدمها ، أو الشركة ، أو الكيان القانوني الآخر الذي يقوم هذا الفرد

            بالوصول إلى الخدمة أو استخدامها نيابةً عنه ، حسب الاقتضاء.

        </p>
        <br>
        <h5>إعتراف</h5>
        <br>
        <p>هذه هي الشروط والأحكام التي تحكم استخدام هذه الخدمة والاتفاقية السارية بينك وبين الشركة. تحدد هذه الشروط
            والأحكام حقوق

            والتزامات جميع المستخدمين فيما يتعلق باستخدام الخدمة.

            إن وصولك إلى الخدمة واستخدامها مشروط بقبولك وامتثالك لهذه الشروط والأحكام. تنطبق هذه الشروط والأحكام على
            جميع

            الزوار والمستخدمين وغيرهم ممن يصلون إلى الخدمة أو يستخدمونها.

            من خلال الوصول إلى الخدمة أو ‍استخدامها ، فإنك توافق على الالتزام بهذه الشروط والأحكام. إذا كنت لا توافق على
            أي جزء من

            هذه الشروط ‍والأحكام ، فلا يجوز لك الوصول إلى الخدمة.

            أنت تقر بأن عمرك يزيد عن 18 عامًا. لا تسمح الشركة لمن هم دون 18 عامًا باستخدام الخدمة.

            إن وصولك إلى الخدمة واستخدامها مشروط أيضًا بقبولك وامتثالك لسياسة الخصوصية الخاصة بالشركة. تصف سياسة

            الخصوصية الخاصة بنا سياساتنا وإجراءاتنا المتعلقة بجمع معلوماتك الشخصية واستخدامها والإفصاح عنها عند استخدام
            التطبيق

            أو الموقع الإلكتروني وتخبرك بحقوق الخصوصية الخاصة بك وكيف يحميك القانون. يرجى قراءة سياسة الخصوصية الخاصة

            بنا بعناية قبل استخدام خدمتنا.</p>
        <br>
        <h5>روابط لمواقع أخرى
        </h5>
        <br>
        <p>قد تحتوي خدمتنا على روابط لمواقع أو خدمات ويب تابعة لجهات خارجية لا تملكها الشركة أو تسيطر عليها.

            لا تتحكم الشركة في المحتوى أو سياسات الخصوصية أو الممارسات الخاصة بأي مواقع ويب أو خدمات تابعة لجهات خارجية

            ولا تتحمل أي مسؤولية عنها. أنت تقر وتوافق أيضًا على أن الشركة لن تكون مسؤولة أو ‍مسؤولة ، بشكل مباشر أو غير
            مباشر ،

            عن أي ضرر أو خسارة ناتجة أو يُزعم أنها ناجمة عن أو فيما يتعلق باستخدام أو الاعتماد على أي محتوى أو سلع أو
            خدمات

            متاحة على أو من خلال أي من مواقع الويب أو الخدمات.

            ننصحك بشدة بقراءة الشروط والأحكام وسياسات الخصوصية الخاصة بأي مواقع ويب أو خدمات تابعة لجهات خارجية تقوم

            بزيارتها</p>
        <br>
        <h5>نهاية</h5>
        <br>
        <p>يجوز لنا إنهاء أو تعليق وصولك على ‍الفور ، دون إشعار مسبق أو مسؤولية ، لأي سبب من الأسباب ، بما في ذلك على
            سبيل

            المثال لا الحصر إذا انتهكت هذه الشروط والأحكام.

            عند ‍الإنهاء ، سيتوقف حقك في استخدام الخدمة على الفور.

        </p>
        <br>
        <h5>تحديد المسؤولية
        </h5>
        <br>
        <p>بغض النظر عن أي أضرار قد ‍تتكبدها ، فإن المسؤولية الكاملة للشركة وأي من مورديها بموجب أي حكم من أحكام هذه
            الشروط

            والتعويض الحصري لكل ما سبق يجب أن يقتصر على المبلغ الذي دفعته بالفعل من خلال الخدمة أو 100 دولار أمريكي إذا
            لم

            تكن قد اشتريت أي شيء من خلال الخدمة.

            إلى أقصى حد يسمح به القانون المعمول به ، لن تتحمل الشركة أو مورديها بأي حال من الأحوال المسؤولية عن أي أضرار

            خاصة أو عرضية أو غير مباشرة أو تبعية من أي نوع (بما في ذلك ، على سبيل المثال لا الحصر ، الأضرار الناجمة عن
            خسارة

            الأرباح أو فقدان البيانات أو معلومات أخرى ، لتوقف الأعمال ، للإصابة الشخصية ، وفقدان الخصوصية الناشئ عن أو
            بأي

            طريقة تتعلق باستخدام أو عدم القدرة على استخدام الخدمة ، وبرامج الطرف الثالث و / أو أجهزة الطرف الثالث
            المستخدمة مع

            الخدمة ، أو بخلاف ذلك فيما يتعلق بأي حكم من أحكام هذه الشروط) ، حتى لو تم إخطار الشركة أو أي مورد بإمكانية
            حدوث مثل

            هذه الأضرار وحتى إذا فشل العلاج في تحقيق غرضه الأساسي.

            لا تسمح بعض الدول باستثناء الضمانات الضمنية أو تحديد المسؤولية عن الأضرار العرضية أو ‍التبعية ، مما يعني أن
            بعض

            القيود المذكورة أعلاه قد لا تنطبق. في هذه ‍الدول ، ستكون مسؤولية كل طرف محدودة إلى أقصى حد يسمح به القانون.
        </p>
        <br>
        <h5>إخلاء المسؤولية "كما هي" و "كما هو متاح"
        </h5>
        <br>
        <p>الخدمة مقدمة لك "كما هي" و "كما هي متوفرة" وبكافة العيوب والعيوب دون ضمان من أي نوع. إلى أقصى حد يسمح به
            القانون

            المعمول ‍به ، تتنصل الشركة ، بالأصالة عن نفسها وبالنيابة عن الشركات التابعة لها ومرخصيها ومقدمي الخدمات
            المعنيين ،

            صراحةً من جميع الضمانات ، سواء كانت صريحة أو ضمنية أو قانونية أو غير ذلك ، فيما يتعلق الخدمة ، بما في ذلك
            جميع

            الضمانات الضمنية الخاصة بالتسويق ، والملاءمة لغرض معين ، والملكية وعدم الانتهاك ، والضمانات التي قد تنشأ عن
            سياق

            التعامل ، أو مسار الأداء ، أو الاستخدام أو الممارسة التجارية. على سبيل المثال لا ‍الحصر ، لا تقدم الشركة أي
            ضمان أو تعهد ،

            ولا تقدم أي تعهد من أي نوع بأن الخدمة سوف تلبي متطلباتك ، أو تحقق أي نتائج مقصودة ، أو تتوافق أو تعمل مع أي
            برامج أو

            تطبيقات أو أنظمة أو خدمات أخرى ، أو تعمل دون انقطاع ، أو تلبية أي معايير أداء أو موثوقية أو أن تكون خالية من
            الأخطاء أو

            أن أي أخطاء أو عيوب يمكن أو سيتم تصحيحها.

            دون تقييد ما سبق ، لا تقدم الشركة ولا أي من أقوال الشركة أي تمثيل أو ضمان من أي نوع ، صريحًا أو ضمنيًا: (1)
            فيما يتعلق

            بتشغيل أو توفر الخدمة ، أو المعلومات ، والمحتوى ، والمواد أو المنتجات المدرجة عليه ؛ (2) أن الخدمة ستكون دون
            انقطاع أو

            خالية من الأخطاء ؛ (3) فيما يتعلق بدقة أو موثوقية أو حداثة أي معلومات أو محتوى مقدم من خلال الخدمة ؛ أو (4)
            أن الخدمة

            أو خوادمها أو المحتوى أو رسائل البريد الإلكتروني المرسلة من الشركة أو بالنيابة عنها خالية من الفيروسات أو
            البرامج النصية

            أو أحصنة طروادة أو الفيروسات المتنقلة أو البرامج الضارة أو القنابل الزمنية أو غيرها من المكونات الضارة.

            لا تسمح بعض الولايات القضائية باستثناء أنواع معينة من الضمانات أو القيود على الحقوق القانونية المعمول بها
            ‍للمستهلك ، لذلك

            قد لا تنطبق عليك بعض الاستثناءات والقيود المذكورة أعلاه أو جميعها. ولكن في مثل هذه ‍الحالة ، يتم تطبيق
            الاستثناءات والقيود

            المنصوص عليها في هذا القسم إلى أقصى حد قابل للتنفيذ بموجب القانون المعمول به.</p>
        <br>
        <h5>القانون الذي يحكم
        </h5><br>
        <p>تحكم قوانين ‍الدولة ، باستثناء تعارضها مع قواعد القانون ، هذه الشروط واستخدامك للخدمة. قد يخضع استخدامك
            للتطبيق أيضًا

            لقوانين ‍محلية أو وطنية أو وطنية أو دولية أخرى.</p>
        <br>
        <h5>حل النزاعات
        </h5><br>
        <p>إذا كان لديك أي قلق أو نزاع حول ‍الخدمة ، فأنت توافق على محاولة حل النزاع أولاً بشكل غير رسمي عن طريق الاتصال

            بالشركة.</p>
        <br>
        <h5>لمستخدمي الاتحاد الأوروبي
        </h5><br>
        <p>إذا كنت مستهلكًا في الاتحاد ‍الأوروبي ، فستستفيد من أي أحكام إلزامية لقانون الدولة التي تقيم فيها.

        </p>
        <br>
        <h5>الامتثال القانوني للولايات المتحدة
        </h5><br>
        <p>تقر وتتعهد بأنك (1) لست مقيمًا في بلد خاضع لحظر حكومة الولايات ‍المتحدة ، أو التي حددتها حكومة الولايات
            المتحدة كدولة "

            داعمة للإرهاب" ، و (2) أنت لست المدرجة في أي قائمة حكومة الولايات المتحدة للأطراف المحظورة أو المقيدة.</p>
        <br>
        <h5>قابلية الفصل والتنازل
        </h5>
        <br>
        <h6>الاستقلالية</h6>
        <br>

        <p>إذا تم اعتبار أي بند من هذه الشروط غير قابل للتنفيذ أو غير ‍صالح ، فسيتم تغيير هذا الحكم وتفسيره لتحقيق أهداف
            هذا الحكم إلى

            أقصى حد ممكن بموجب القانون المعمول به وستظل الأحكام المتبقية سارية المفعول والتأثير الكامل.

        </p>
        <br>
        <h6>تنازل</h6>
        <br>
        <p>باستثناء ما هو منصوص عليه ‍هنا ، فإن الإخفاق في ممارسة حق أو طلب أداء التزام بموجب هذه الشروط لا يؤثر على
            قدرة

            الطرف على ممارسة هذا الحق أو يتطلب مثل هذا الأداء في أي وقت بعد ذلك ولا يشكل التنازل عن الخرق تنازلاً عن أي
            خرق

            لاحق.</p>
        <br>
        <h5>ترجمة فورية
        </h5>
        <br>
        <p>ربما تمت ترجمة هذه الشروط والأحكام إذا قمنا بتوفيرها لك على خدمتنا. أنت توافق على أن النص الأصلي باللغة
            الإنجليزية هو

            الذي يسود في حالة النزاع.</p>
        <br>
        <h5>التغييرات على هذه الشروط والأحكام
        </h5>
        <br>
        <p>نحتفظ ‍بالحق ، وفقًا لتقديرنا الخاص ، في تعديل أو استبدال هذه الشروط في أي وقت. إذا كانت المراجعة جوهرية ،
            فسنبذل جهودًا

            معقولة لتقديم إشعار قبل 30 يومًا على الأقل قبل دخول أي شروط جديدة حيز التنفيذ. سيتم تحديد ما يشكل تغييرًا
            جوهريًا وفقًا

            لتقديرنا الخاص.

            من خلال الاستمرار في الوصول إلى خدمتنا أو استخدامها بعد أن تصبح هذه المراجعات ‍فعالة ، فإنك توافق على
            الالتزام

            بالشروط المعدلة. إذا كنت لا توافق على الشروط ‍الجديدة ، كليًا أو جزئيًا ، فيرجى التوقف عن استخدام الموقع
            الإلكتروني

            والخدمة.</p>
    </div>
</div>







