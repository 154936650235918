import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contact } from '../model/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactPageService {
baseApi=environment.baseApi
  constructor(private http:HttpClient) { }


sendMessageService(contact:Contact):Observable<Contact>{
 return this.http.post<Contact>(`${this.baseApi}/contact-message/public`,contact)
}
}
