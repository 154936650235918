
  <div class="container bg ">
    
  <div class="row pos">
    <div class="col-md-6 col3 wow zoomIn ">
        
        
            <img src="assets/img/Illustration5.svg" width="65%" alt="app-img">
    
    </div>
    <div class="col-md-6   droid-arabic-kufi main-banner-content">
        
        <div lang="ar" dir="rtl" class="app-download-content wow zoomIn">
            <h1 lang="ar" dir="rtl" class="sub-title">مع  ms soap
            </h1>
            <h2 lang="ar" dir="rtl" class="col2">مغسلتك على بابك
            </h2>
            <p lang="ar" dir="rtl" class="text">ساكن في شقة ودايم تروح للمغسلة؟ لا تفوتك شنطة مغاسل. اضف الشنطة الى طلبك واستمتع بخدمة غسيل مريحة جداً من دون اي تواصل مع مندوب التوصيل ابداً ولا يحتاج تواجدك في شقتك وقت التسليم والاستلام

            </p>
            <div class="container">
                <div class="row">
                  <div class="col position">
                    <a href="#" class="playstore-btn" target="_blank">
                        <img src="assets/img/app-store3.svg"  alt="image">
                       
                    </a>
                  </div>
                  <div class="col position">
                    <a href="#" class="applestore-btn" target="_blank">
                        <img src="assets/img/google-play3.svg" alt="image">
                    </a>                                              </div>
                </div>
            </div>
            
        </div>    
    </div>
  </div>
</div>